import type {
  PaymentGroup,
  PaymentMethod,
  CountryDetail,
  Country,
  CurrenciesEnum,
} from "~/api_gen";
import useMobileCheck from "~/composables/useMobileCheck";

export const usePaymentsStore = defineStore("paymentsStore", () => {
  const { t } = useI18n();
  const notify = useNotify();
  const api = useApi();
  const userStore = useUserStore();

  /** отвечает за показ popup успешной оплаты */
  const successPopupRequired = ref(false);
  /** отвечает за показ popup проваленной оплаты */
  const failurePopupRequired = ref(false);

  /**
   * Текущий выбранный метод оплаты.
   */
  const paymentMethod = ref<PaymentMethod | null>(null);

  /**
   * Список доступных платежных методов.
   */
  const paymentMethods = ref<PaymentMethod[]>([]);

  /**
   * Список платежных методов объединенных по группам.
   */
  const paymentMethodsByGroups = ref<PaymentGroup[]>([]);

  /**
   * Список методов отфильтрованные по группе, например,
   * "Карты", "Телефон", "Крипта" и т.д.
   */
  const paymentMethodsFiltered = ref<PaymentGroup[]>([]);

  /**
   * Список с наименованиями платежных груп.
   */
  const paymentGroupNames = computed(() =>
    paymentMethodsByGroups.value.map(
      (paymentMethodsGroup) => paymentMethodsGroup.shortName,
    ),
  );

  /**
   * Список элементов передаваяемых в селект выбора платежной группы.
   */
  const paymentSelectItems = computed(() => {
    return paymentGroupNames.value.map((item) => {
      return {
        title: item,
        value: item,
      };
    });
  });

  /**
   * Наименование выбранной платежной группы ("Карты", "Телефон", "Крипта" и т.д.).
   */
  const selectedPaymentsGroup = ref("");

  /**
   * Объект с данными по текущей выбранной стране.
   */
  const activeCountry = ref<CountryDetail>();

  /**
   * Список с данными по всем странам.
   */
  const allCountries = ref<Country[]>([]);

  /**
   * Валютный код текущей выбранной страны ("RUB", "USD" и т.д.).
   */
  const countryCurrency = ref("");

  /**
   * Наименование текущей выбранной страны.
   */
  const selectedCountryName = ref("");

  /**
   * Код флага выбранной страны ("ru", "uz" и т.д.).
   */
  const selectedCountryFlag = ref("");

  /**
   * Текущий выбранный Валютный код ("RUB", "USD" и т.д.).
   */
  const selectedCurrency = ref("");

  /**
   * Список доступных валют.
   */
  const availableCurrencies = computed<string[]>(() => {
    const methods = paymentMethodsByGroups.value.flatMap(
      (item) => item.methods,
    );
    return [...new Set(methods.flatMap((item) => item.currencies))];
  });

  /**
   * Телефон, который указал покупатель в форме платежа.
   */
  const phone = ref("");

  /**
   * Ошибка ввода телефон, который указал покупатель в форме платежа.
   */
  const phoneError = ref("");

  /**
   * Курс рубля по отношению к валюте выбранной страны.
   */
  const rate = computed(() =>
    activeCountry.value ? parseFloat(activeCountry.value.rate) : 1,
  );

  /**
   * Индикатор блокировки всех процессов.
   */
  const blocked = ref(false);

  /**
   * Дизэйбл кнопки
   */
  const disabled = ref(false);

  /**
   * Инициализирует страницу платежей необходимымы данными во время ее рендеринга:
   * совершает запрос на получение списка доступных стран и плаежных методов
   * выбранной страны, устанавливает первоначально выбранный платежный метод и т.д.
   */
  async function initTopupPage() {
    if (!userStore.userIsAuthorized) return;
    if (!paymentMethodsByGroups.value.length) {
      await getPaymentMethodsByActiveCountry();
      await getAllCountries();
      paymentMethodsFiltered.value = paymentMethodsByGroups.value;
    }
    selectPaymentMethodDuringInit();
  }

  /**
   * В момент инициализации страницы платежей,
   * если она загружена на десктопе и доступны платежные методы,
   * выбирает из них первый подходящий.
   */
  function selectPaymentMethodDuringInit() {
    if (paymentMethodsFiltered.value.length && !useMobileCheck()) {
      const productsStore = useProductsStore();
      for (let i = 0; i < paymentMethodsFiltered.value.length; i++) {
        const methodsGroup = paymentMethodsFiltered.value[i].methods;
        for (let j = 0; j < methodsGroup.length; j++) {
          const method = methodsGroup[j];
          if (method.isActive && method.minAmount < productsStore.totalCost) {
            paymentMethod.value = method;
            return;
          }
        }
      }
    }
  }

  /**
   * Получает список платежных методов текущей выбранной страны.
   */
  async function getPaymentMethodsByActiveCountry() {
    try {
      blocked.value = true;
      activeCountry.value = await api.payments.countriesActiveRetrieve();
      paymentMethodsByGroups.value = activeCountry.value.methodGroups;
      paymentMethods.value = paymentMethodsByGroups.value.flatMap(
        (group) => group.methods,
      );
      selectedCountryName.value = activeCountry.value.name;
      selectedCountryFlag.value = activeCountry.value.flag;
      countryCurrency.value = activeCountry.value.currency;
    } catch (e: any) {
      /* empty */
    } finally {
      blocked.value = false;
    }
  }

  /**
   * Получает список всех доступных стран.
   */
  async function getAllCountries(): Promise<void> {
    try {
      blocked.value = true;
      allCountries.value = await api.payments.countriesList();
    } catch (e: any) {
      /* empty */
    } finally {
      blocked.value = false;
    }
  }

  /**
   * Возвращает список методов отфильтрованный по группе.
   *
   * @param groupName - наименование группы методов ("Карты", "Телефон", "Крипта" и т.д.).
   */
  function filterPaymentMethodsByGroup(groupName: string) {
    return paymentMethodsByGroups.value.filter(
      (method) => method.shortName === groupName,
    );
  }

  /**
   * Выбирает страну, получая и отображая список ее платежных методов.
   */
  async function selectCountry(item: Country) {
    try {
      blocked.value = true;
      await postCountyId(item.id);
      selectPaymentGroup("");
      selectedCountryName.value = item.name;
      paymentMethodsFiltered.value = paymentMethodsByGroups.value;
      if (!useMobileCheck() || paymentMethod.value) {
        selectPaymentMethod(paymentMethodsFiltered.value[0].methods[0]);
      }
    } catch (error) {
      notify({
        type: "error",
        text: t("top_up_balance.errors.countries_list_id_error"),
      });
    } finally {
      blocked.value = false;
    }
  }

  /**
   * Отображает список платежных методов принадлежащих указанной группе.
   * Если группа равна пустой строке, отображает все платежные методы страны.
   *
   * @param name - наименование группы платежных методов ("Карты", "Телефон", "Крипта" и т.д.).
   */
  function selectPaymentGroup(groupName: string) {
    if (groupName) {
      paymentMethodsFiltered.value = filterPaymentMethodsByGroup(groupName);
    } else {
      paymentMethodsFiltered.value = paymentMethodsByGroups.value;
    }
    selectedPaymentsGroup.value = groupName;
  }

  /**
   * Получает платежные методы указанной страны.
   *
   * @param id - id выбранной страны.
   */
  async function postCountyId(id: number) {
    try {
      blocked.value = true;
      activeCountry.value = await api.payments.countriesActiveSelect({
        selectActiveCountryRequest: {
          countryId: id,
        },
      });
      paymentMethodsByGroups.value = activeCountry.value.methodGroups;
      paymentMethods.value = paymentMethodsByGroups.value.flatMap(
        (group) => group.methods,
      );
      selectedCountryName.value = activeCountry.value.name;
      selectedCountryFlag.value = activeCountry.value.flag;
      countryCurrency.value = activeCountry.value.currency;
    } catch (e: any) {
      notify({
        type: "error",
        text: t("top_up_balance.errors.countries_list_id_error"),
      });
    } finally {
      blocked.value = false;
    }
  }

  /**
   * Делает указанный платежный метод выбранным.
   *
   * @param method - платежный метод для выбора.
   */
  function selectPaymentMethod(method: PaymentMethod) {
    if (method.isActive) {
      paymentMethod.value = method;
    } else {
      paymentMethod.value = null;
    }
  }

  /**
   * Сбрасывает текущий выбранный платежный метод.
   */
  function dropSelectedPaymentMethod() {
    paymentMethod.value = null;
  }

  /**
   * Совершает запрос на оплату заказа.
   */
  async function makePayment() {
    if (blocked.value || !paymentMethod.value?.name) return;
    blocked.value = true;
    try {
      const productsStore = useProductsStore();
      const data = await useApi().payments.payments({
        name: paymentMethod.value.name,
        makePaymentRequest: {
          orderId: productsStore.orderId,
          currency: countryCurrency.value as CurrenciesEnum,
          email: productsStore.inputValueMail,
        },
      });
      productsStore.deleteOrderFromStorage();
      productsStore.deleteOrderAmounts();

      if ("url" in data) {
        window.open(data.url, "_blank");
      } else {
        notify({
          text: t("forms.order_user_data.payment_done"),
        });
        disabled.value = true;
      }
    } catch (e: any) {
      notify({
        text: t("top_up_balance.errors.payment_error"),
        type: "error",
      });
    } finally {
      blocked.value = false;
    }
    try {
      await userStore.getUser();
    } catch (e) {
      /* empty */
    }
    try {
      await userStore.getUserOrders();
    } catch (e) {
      /* empty */
    }
  }
  /**
   * Совершает запрос на оплату заказа.
   */
  async function getOrder(orderId: number) {
    return api.orders.retrieve({ id: orderId });
  }

  const checkPaymentStatus = async (orderId: string) => {
    const res = await api.orders.statusRetrieve({ id: Number(orderId) });

    if ("status" in res) {
      if (res.paymentStatus === 1) {
        successPopupRequired.value = true;
      }
      if (res.paymentStatus === 2) {
        failurePopupRequired.value = true;
      }
    }
    return res;
  };

  return {
    selectedPaymentsGroup,
    paymentGroupNames,
    paymentMethodsFiltered,
    paymentSelectItems,
    paymentMethodsByGroups,
    paymentMethod,
    paymentMethods,
    availableCurrencies,
    countryCurrency,
    allCountries,
    selectedCountryName,
    selectedCountryFlag,
    activeCountry,
    selectedCurrency,
    phone,
    phoneError,
    rate,
    blocked,
    disabled,
    successPopupRequired,
    failurePopupRequired,
    initTopupPage,
    getPaymentMethodsByActiveCountry,
    getAllCountries,
    postCountyId,
    filterPaymentMethodsByGroup,
    selectCountry,
    selectPaymentGroup,
    selectPaymentMethod,
    dropSelectedPaymentMethod,
    makePayment,
    getOrder,
    checkPaymentStatus,
  };
});
